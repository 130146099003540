// @codekit-prepend "../../bower_components/slick-carousel/slick/slick.min.js";
// @codekit-prepend "../../bower_components/enquire/dist/enquire.js";
// @codekit-prepend "../../bower_components/waypoints/lib/jquery.waypoints.js";
// @codekit-prepend "../../bower_components/waypoints/lib/shortcuts/sticky.js";
// @codekit-prepend "../../bower_components/headroom.js/dist/headroom.js";
// @codekit-prepend "../../bower_components/headroom.js/dist/jQuery.headroom.js";
// @codekit-prepend "../../bower_components/headroom.js/dist/angular.headroom.js";
// @codekit-prepend "../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/transition.js";
// @codekit-prepend "../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/alert.js";
// @codekit-prepend "../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/button.js";
// @codekit-prepend "../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/carousel.js";
// @codekit-prepend "../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/collapse.js";
// @codekit-prepend "../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/dropdown.js";
// @codekit-prepend "../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/modal.js";
// @codekit-prepend "../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/tooltip.js";
// @codekit-prepend "../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/popover.js";
// @codekit-prepend "../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/scrollspy.js";
// @codekit-prepend "../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/tab.js";
// @codekit-prepend "../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/affix.js";
// @codekit-prepend "../../bower_components/savvior/dist/savvior.js";

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        
        // Deep link ChowNow overlay to specific locations
			
          $('a.chownow-cta').on('click', function() {
            $link = $(this).attr('ref');
            $location = $(this).attr('location');
            var $i = 0;
            $('iframe.chownow-iframe').on('load', function(){
                  $chownow = $('iframe.chownow-iframe').attr('src');
                  if($i == 0) {
                    $('iframe.chownow-iframe').attr('src', $link);
                    $i = 1;
                  }
              }); 
          });

        // GA event tracking triggers
        var $url = window.location.href;
        
        // Online order buttons
        $('.chownow-order-online').on('click', function(e) {
          ga('send', 'event', 'Online Order', 'Click', $url);
        });

        // Mobile deep link online order cta buttons
        $('a.chownow-cta.show-on-mobile, a.chownow-cta.visible-sm').on('click', function(e) {
          ga('send', 'event', 'Online Order', 'Click', $url);
        });


        // toggle order online modal
        $("body").on("click", ".order-modal-trigger", function(e) {
          e.preventDefault();
          e.stopPropagation();
          $("#orderModal").modal();
        });

        // Newsletter Signup
        $('#mc-embedded-subscribe-form').on('submit', function(e) {
			e.preventDefault();
			ga('send', 'event', 'Newsletter Sign Up', 'submit', $url);
			this.submit();
        });

        // Locations click on address/map link
        $('a[itemprop=hasMap]').on('click', function(e) {
    	  $location = $(this).parent().parent().find('h3').html();
    	  $city = $(this).find('span[itemprop=addressLocality]').html();
    	  $location = $city + " - " + $location;
          ga('send', 'event', 'Get Directions', 'Click', $location);
        });

        // Click to call location number
        $('a[href^=tel\\:]').on('click', function(e) {
          $location = $(this).parent().parent().find('h3').html();
          $city = $(this).parent().find('span[itemprop=addressLocality]').html();
          $location = $city + " - " + $location;
          ga('send', 'event', 'Phone', 'Click To Call', $location);
        });

        // Add to cart button
        $('.add_to_cart_button, .single_add_to_cart_button').on('click', function(e) {
          ga('send', 'event', 'Add To Cart', 'Click', $url);
        });

        // Proceed to PayPal Checkout button
        $('#order_review').on('click', '#place_order', function(e) {
          ga('send', 'event', 'Proceed To Checkout', 'Click', $url);
        });

        // Small screen menu toggle
        $('.banner').on('click', 'button.menu-toggle', function() {
          $(this).toggleClass('active');
          $('.nav-primary').toggleClass('nav-toggled');
        });

        // control clicky controls for various sections
        $('body').on('click', '.toggle-parent-control', function(e) {
          e.preventDefault();
          $(this).toggleClass('active');
          $(this).parent('.toggle-target').toggleClass('active');
        });
        $('body').on('click', '.toggle-control', function(e) {
          e.preventDefault();
          $(this).toggleClass('active');
          $(this).next('.toggle-target').toggleClass('active');
        });

        // smooth scrolling
        $('body').on('click', 'a[href*=\\#]:not([href=\\#])', function() {
          if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') || location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            
                    	// parse locations hash to expand location region listing
            
            $region = $(this).attr('href');
            $hero_region = $region + 'hero-region';

            $($region).addClass('active');
            
             if (target.length) {
               $('html,body').animate({
                 scrollTop: target.offset().top
              }, 1000);
              return false;
              

            
            }
            
          }
                   
        });

		var sticky2 = new Waypoint.Sticky({
          element: $('.nav-header')[0]
        });

        $('.nav-header').headroom({
					tolerance : {
						up : 5,
						down : 0
					},
					classes : {
						initial : "slippery",
						pinned : "pinned",
						unpinned : "unpinned", 
						top : "top",
						notTop : "not-top"
					},
		});

		$( window ).resize(function() { 
		
			sticky2.destroy();
			sticky2 = new Waypoint.Sticky({
          		element: $('.nav-header')
        	});
		}); 

        // waypoints  sticky nav 
       var subNav = $('.subnav')[0];
       if (typeof(subNav) == 'object' ) {
        var sticky = new Waypoint.Sticky({
          element: $('.subnav')[0]
        });

        $('.subnav').headroom({
					tolerance : {
						up : 5,
						down : 0
					},
					classes : {
						initial : "slippery",
						pinned : "pinned",
						unpinned : "unpinned",
						top : "top",
						notTop : "not-top"
					},
				});
				
      }
     }
      
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'menu': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('.carrousel').slick({
          dots: true,
          prevArrow: '<div class="hidden-xs hidden-sm"><button type="button" class="slick-prev"><span class="icon-nav-left"></button></div>',
          nextArrow: '<div class="hidden-xs hidden-sm"><button type="button" class="slick-next"><span class="icon-nav-right"></span></button></div>',
        });

        // Savvior.js
//        savvior.init(".savvior-layout-4", {
//          "screen and (max-width: 767px)": { columns: 1 },
//          "screen and (min-width: 768) and (max-width: 991px)": { columns: 2 },
//          "screen and (min-width: 992px)": { columns: 4 },
//        });
//        savvior.init(".savvior-layout-3", {
//          "screen and (max-width: 767px)": { columns: 1 },
//          "screen and (min-width: 992px)": { columns: 3 },
//        });
//        savvior.init(".savvior-layout-2", {
//          "screen and (max-width: 767px)": { columns: 1 },
//          "screen and (min-width: 768px)": { columns: 2 },
//        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
